import { AbstractControl, ValidationErrors } from '@angular/forms';

// Function 'passwordsMatchValidator' takes in 'control' as a parameter, which is an instance of AbstractControl
// The function checks if the 'password' and 'confirmPassword' values of the control instance are the same
// If not, it returns an error object { passwordsNotMatching: true }, otherwise returns null
export function passwordsMatchValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.get('password')?.value;
  const confirmPassword = control.get('confirmPassword')?.value;
  return password === confirmPassword ? null : { passwordsNotMatching: true };
}

// Function 'passwordStrengthValidator' takes in 'control' as a parameter, which is an instance of AbstractControl
// The function then evaluates the strength of the password based on the following criteria:
// - contains an uppercase letter
// - contains a lowercase letter
// - contains a number
// - length is greater than 7
// If the password does not meet any of these criteria, the function returns an error object { weakPassword: true, hasUpperCase, hasNumeric, hasLowerCase, hasLength }, otherwise returns null
export function passwordStrengthValidator(control: AbstractControl): ValidationErrors | null {
  const value: string = control.value;
  if (!value) {
    return null;
  }

  const hasUpperCase = /[A-Z]+/.test(value);
  const hasLowerCase = /[a-z]+/.test(value);
  const hasNumeric = /[0-9]+/.test(value);
  const hasLength = value.length > 7;
  const passwordStrong = hasUpperCase && hasLowerCase && hasNumeric && hasLength;

  return !passwordStrong ? { weakPassword: true, hasUpperCase, hasNumeric, hasLowerCase, hasLength } : null;
}
